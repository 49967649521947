import "./App.css";

export default function App() {
  return (
    <div>
      <header className="App-header">
        <span>Website currently under development</span>
        <span>
          {`For more info contact `}
          <a href="mailto:info@arcemsecure.com" className="text-white">
            info@arcemsecure.com
          </a>
        </span>
      </header>
    </div>
  );
}
